import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import PropsTable from '../components/layout/PropsTable';
import { Section } from '../components/common/';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
`;

export default class ComponentPage extends Component {
  render() {
    const { children, data } = this.props;

    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <Grid>
          <Section
            lightBackground
            padding="1.5rem"
            style={{ position: 'sticky' }}
          >
            <h4>Base Website Docs</h4>
            <hr />
            <ul>
              <li>
                <Link to="/docs/">Overview</Link>
              </li>
              <li>
                <Link to="/docs/content">Content Management</Link>
              </li>
              <li>
                <Link to="/docs/developers">Developers</Link>
              </li>
            </ul>
            <hr />
            <h4>Components</h4>
            <ul>
              <li>
                <Link to="/docs/components">What is a component?</Link>
              </li>
              {data.allComponentMetadata.edges.map(({ node: doc }) => (
                <li key={doc.id}>
                  <Link to={`/docs/${doc.displayName}/`}>
                    {doc.displayName}
                  </Link>
                </li>
              ))}
            </ul>
          </Section>

          <Section>
            <div className="content">
              {children}
              <h1>{data.componentMetadata.displayName}</h1>
              <p>{data.componentMetadata.docblock}</p>
              <h2 style={{ marginTop: '2rem' }}>Props:</h2>
              <PropsTable
                propMetaData={data.componentMetadata.childrenComponentProp}
              />
            </div>
          </Section>
        </Grid>
      </>
    );
  }
}
export const pageQuery = graphql`
  query($name: String!) {
    allComponentMetadata {
      edges {
        node {
          id
          displayName
        }
      }
    }
    componentMetadata(displayName: { eq: $name }) {
      id
      displayName
      docblock
      doclets
      childrenComponentProp {
        name
        docblock
        required
        parentType {
          name
        }
        type {
          value
        }
        defaultValue {
          value
          computed
        }
      }
      composes
    }
  }
`;
